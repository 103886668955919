import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import selectIcon from "../../assets/icons/select.png";
import Loader from "../../components/loader/Loader";
import "../../App.css";
import { SlReload } from "react-icons/sl";
import { exportToSpreadSheet } from "../../components/xlsx/ExportToSpreadSheet";
import Moment from "react-moment";

const TradeHistory = () => {
  const {
    setPageName,
    loading,
    getTradeDemoHistory,
    setTradeDemoHistory,
    getHistoryData,
  } = useContext(MyContext);

  const [isToggledTodayOverAll, setIsToggledTodayOverAll] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pnl, setPnl] = useState(0);
  const [totalTrade, setTotalTrade] = useState(0);
  const [profitTrade, setProfitTrade] = useState(0);
  const [lossTrade, setLossTrade] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0);
  const [lossAmount, setLossAmount] = useState(0);
  const [netPnl, setNetPnl] = useState(0);

  // Handler for start date change
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  // Handler for end date change
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleToggle = () => {
    setIsToggledTodayOverAll(!isToggledTodayOverAll);
  };

  const positionType = (type) => {
    console.log("type : ", type);

    if (type === "Intraday")
      return { type: "MIS", color: "#013052", backgroundColor: "#afdafa" };
    else if (type === "Longterm")
      return { type: "CNC", color: "#7A282F", backgroundColor: "#F8D7DA" };
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Selected Date Range: ${startDate} to ${endDate}`);
  };

  const getHistoryDataWithDate = async (e) => {
    e.preventDefault();
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    let body = {
      from: startDate,
      to: endDate,
    };
    console.log("tradeHistoroy search body : ", body);

    await fetch("https://app.stoxviews.com/api/tradehistorydemo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("search tradehistorydemo with dates value : ", value);

        if (value.status) {
          //let hdata = value.data;
          setTradeDemoHistory(value.data);
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  const convertUtcToIst = (udate) => {
    const istFormattedDate = Moment.utc(udate)
      .utcOffset("+05:30")
      .format("DD/MM/YY, h:mmA");
    return istFormattedDate;
  };

  useEffect(() => {
    setPageName("tradeHistory");
  }, []);

  useEffect(() => {
    setProfitTrade(
      getTradeDemoHistory.filter((item, index) => item.pnl >= 0).length
    );
    setLossTrade(
      getTradeDemoHistory.filter((item, index) => item.pnl < 0).length
    );
    setProfitAmount(
      getTradeDemoHistory
        .filter((entry) => entry.pnl > 0)
        .reduce((sum, entry) => sum + entry.pnl, 0)
        .toFixed(2)
    );
    setLossAmount(
      getTradeDemoHistory
        .filter((entry) => entry.pnl < 0)
        .reduce((sum, entry) => sum + entry.pnl, 0)
        .toFixed(2)
    );
    let profit = getTradeDemoHistory
      .filter((entry) => entry.pnl >= 0)
      .reduce((sum, entry) => sum + entry.pnl, 0);

    let loss = getTradeDemoHistory
      .filter((entry) => entry.pnl < 0)
      .reduce((sum, entry) => sum + entry.pnl, 0);

    setPnl(profit + loss);
  }, [getHistoryDataWithDate]);

  return (
    <Layout>
      <div className="p-4">
        {loading && <Loader />}
        <h1 className="text-xl font-bold mr-[36px]">Trade History</h1>

        <div className="flex justify-between items-center">
          <div style={{ width: "50%" }}>
            <form onSubmit={getHistoryDataWithDate}>
              <div className="flex flex-row justify-between  mt-[20px] mb-[20px]">
                <div className="flex flex-col">
                  <label htmlFor="start-date">From Date: </label>
                  <input
                    type="date"
                    id="start-date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    style={{
                      padding: "8px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      borderWidth: "2px",
                      borderColor: "#ccc",
                      borderStyle: "solid",
                      marginTop: "5px",
                      //   width: "200px",
                      outline: "none",
                      transition: "border-color 0.3s ease",
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="end-date">To Date: </label>
                  <input
                    type="date"
                    id="end-date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    min={startDate}
                    style={{
                      padding: "8px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      borderWidth: "2px",
                      borderColor: "#ccc",
                      borderStyle: "solid",
                      marginTop: "5px",
                      //   width: "200px",
                      outline: "none",
                      transition: "border-color 0.3s ease",
                    }}
                  />
                </div>
                <div className="flex items-end justify-center">
                  <button
                    type="submit"
                    style={{
                      padding: "8px 19px",
                      fontSize: "16px",
                      backgroundColor: "#007BFF",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div
            className="flex flex-row-reverse"
            style={{
              width: "50%",
            }}
          >
            <div className="flex items-center">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                  onClick={() => {
                    console.log("trade history : ", getTradeDemoHistory);
                    exportToSpreadSheet(getTradeDemoHistory, "TradeHistory");
                  }}
                />
              </button>
              <button
                className="ml-2"
                onClick={() => {
                  getHistoryData();
                }}
              >
                <SlReload />
              </button>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-row items-center justify-between mb-4">
          <div className="flex  items-center ">
            <div className="flex space-x-1">
              <button
                className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                style={{
                  fontSize: 14,
                  //   color: isAllSelected ? "#155724" : "#817E7E",
                  //   backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                }}
              >
                ALL | 0
              </button>

              {positionStatusCount.map((item, index) => {
                return (
                  <button
                    className="px-3 py-1 border"
                    key={index}
                    style={{ borderColor: "#817E7E" }}
                    // onClick={() => onChangePositionStatusCount(item, index)}
                    style={{
                      fontSize: 12,
                      //   color: item.isSelected ? "#155724" : "#817E7E",
                      //   backgroundColor: item.isSelected ? "#D4EDDA" : "#FFFFFF",
                    }}
                  >
                    {item.status} | {item.value}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="flex flex-row items-center z-0">
            <div className="mr-[7px]">
              <span
                style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
              >
                Today
              </span>
            </div>

            <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
              <button
                className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                style={{
                  backgroundColor: isToggledTodayOverAll ? "green" : "green  ",
                }}
                onClick={() => handleToggle()}
              >
                <div
                  style={{ backgroundColor: "white" }}
                  className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                    isToggledTodayOverAll ? "translate-x-7" : "translate-x-1"
                  }`}
                />
              </button>
            </div>
            <div className="mr-[21px]">
              <span
                style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
              >
                Overall
              </span>
            </div>
          </div>

          <div className="mr-[16PX]">
            <button>
              <img
                src={DownloadIcon}
                alt="download"
                style={{ width: 14, height: 16 }}
              />
            </button>
            <button className="ml-2">
              <SlReload />
            </button>
          </div>
        </div> */}

        <table className="min-w-full bg-white orderTable">
          <thead className="orderTable">
            <tr>
              <th className="px-4 py-2">
                <img
                  src={selectIcon}
                  alt="select option"
                  style={{ width: 16, height: 16 }}
                />
              </th>
              <th className="px-4 py-2 border">Buy Date</th>
              <th className="px-4 py-2 border">Instrument </th>
              <th className="px-4 py-2 border">Type</th>
              <th className="px-4 py-2 border">Product</th>
              <th className="px-4 py-2 border">Buy Qty</th>
              <th className="px-4 py-2 border">Sell Qty</th>
              <th className="px-4 py-2 border">Buy Price</th>
              <th className="px-4 py-2 border">Sell Price</th>
              <th className="px-4 py-2 border">Close Date</th>
              <th className="px-4 py-2 border">PNL</th>
            </tr>
          </thead>
          <tbody>
            {getTradeDemoHistory.map((item, index) => {
              const openDate = item.open_date;
              const closeDate = item.close_date;
              // console.log("open date : ",openDate);

              // const openDate = convertUtcToIst(item.open_date);
              // const closeDate = convertUtcToIst(item.close_date);

              return (
                <tr key={index}>
                  <td className="px-4 py-2 border orderTabletd">
                    <div>
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </div>
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {/* <Moment format="DD/MM/YY, h:mmA" date={openDate} tz="Asia/Kolkata" /> */}
                    <Moment format="DD/MM/YY, h:mmA">{openDate}</Moment>
                    {/* {openDate} */}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {item.token_id__trading_symbol}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    <button
                      style={{
                        fontSize: 10,
                        paddingLeft: 4,
                        paddingRight: 4,
                        height: 20,
                        width: "100%",
                        borderRadius: 3,
                        backgroundColor: "rgb(212, 237, 218)",
                        color: "rgb(21, 87, 36)",
                      }}
                    >
                      {item.otype}
                    </button>
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    <button
                      style={{
                        fontSize: 10,
                        paddingLeft: 4,
                        paddingRight: 4,
                        height: 20,
                        width: "100%",
                        borderRadius: 3,
                        color: positionType(item.ptype)?.color,
                        backgroundColor: positionType(item.ptype)
                          ?.backgroundColor,
                      }}
                    >
                      {positionType(item.ptype)?.type}
                    </button>
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {item.buy_qty}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {item.sell_qty}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {item.buy_avg}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {item.sell_avg}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {/* <Moment format="DD/MM/YY, h:mmA" date={closeDate} tz="Asia/Kolkata" /> */}
                    <Moment format="DD/MM/YY, h:mmA">{closeDate}</Moment>
                    {/* {closeDate} */}
                  </td>
                  <td
                    className="px-4 py-2 border orderTabletd"
                    style={{ color: item.pnl >= 0 ? "green" : "red" }}
                  >
                    {item.pnl.toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="mt-[27px] mb-[15px]  flex justify-end space-x-9 px-6 ">
          <div>
            <div className="text-xs text-gray-500">Total Trades</div>
            <div className="flex flex-row items-center justify-center">
              <span className="text-lg font-bold">
                {getTradeDemoHistory.length}
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Profitable Trades</div>
            <div className="flex flex-row items-center justify-center">
              <span className="text-lg font-bold" style={{ color: "green" }}>
                {profitTrade}
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Loss Trades</div>
            <div className="flex flex-row items-center justify-center">
              <span className="text-lg font-bold" style={{ color: "#DF2123" }}>
                {lossTrade}
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Profit Amount</div>
            <div className="flex flex-row items-center justify-center">
              <span className="text-lg font-bold" style={{ color: "green" }}>
                {profitAmount}
              </span>
            </div>
          </div>
          <div>
            <div className="text-xs text-gray-500">Loss Amount</div>
            <div className="flex flex-row items-center justify-center">
              <span className="text-lg font-bold" style={{ color: "#DF2123" }}>
                {lossAmount}
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Net PNL</div>
            <div className="flex flex-row items-center justify-center">
              <span
                className="text-lg font-bold"
                style={{ color: pnl <= 0 ? "#DF2123" : "green" }}
              >
                {pnl.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TradeHistory;
