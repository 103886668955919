import React, { useEffect, useContext, useState, useRef } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import Loader from "../../components/loader/Loader";
import "../../App.css";
import "../../styles/order.css";
import Moment from "react-moment";
import { SlReload } from "react-icons/sl";
import { exportToSpreadSheet } from "../../components/xlsx/ExportToSpreadSheet";
import { FaRegEdit } from "react-icons/fa";

function Popup({ onClose, reason }) {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div style={popupStyle}>
      <div style={popupContentStyle} ref={popupRef}>
        {/* <h2>Reason</h2> */}
        <p style={{ color: "red" }}>{reason}</p>
        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
}

const popupStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const popupContentStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "5px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
};

function Orders() {
  const {
    setWatchListShown,
    setPageName,
    loading,
    setLoading,
    isDemoPage,
    isMasterOrChildOrder,
    setBopen,
    setIsPendingOrder,
    setBuyAndSellTradingSymbol,
    setBuyAndSellExchangeToken,
    setChartToken,
    setBuyAndSellExchange,
    setBuyAndSellModalLivePrice,
    qty,
    setQty,
    setLotSize,
    setOrderId,
    setModificationType,
    setBuyAndSellAction,
    orderType,
    setOrderType,
    productType,
    setProductType,
    isTriggerRequired,
    setIsTriggerRequired,
    isTriggerDisabled,
    setIsTriggerDisabled,
    isDisabled,
    setIsDisabled,
    marginCalculation,
  } = useContext(MyContext);
  const [orders, setOrders] = useState([]);
  const [renderOrder, setRenderOrder] = useState([]);
  const [orderStatusCount, setOrderStatusCount] = useState([
    { status: "Pending", isSelected: false },
    { status: "Confirmed", isSelected: false },
    { status: "Rejected", isSelected: false },
  ]);

  const [isAllSelected, setIsAllSelected] = useState(true);
  const [totalPending, setTotalPending] = useState(0);
  const [totalConfirmed, setTotalConfirmed] = useState(0);
  const [totalRejected, setTotalRejected] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderMasterMonth, setOrderMasterMonth] = useState(0);
  const [orderChildMonth, setOrderChildMonth] = useState(0);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;
  const totalPages = Math.ceil(renderOrder.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = renderOrder.slice(indexOfFirstItem, indexOfLastItem);

  const statusClasses = {
    COMPLETED: { bgcolor: "#D4EDDA", textColor: "#155724" },
    REJECTED: { bgcolor: "#F8D7DA", textColor: "#721C24" },
    PENDING: { bgcolor: "#B6E0FF", textColor: "#013052" },
  };

  const statusBgColor = (status) => {
    let bg = "";
    switch (status) {
      case "Confirmed":
        bg = statusClasses.COMPLETED.bgcolor;
        break;

      case "Rejected":
        bg = statusClasses.REJECTED.bgcolor;
        break;

      case "Pending":
        bg = statusClasses.PENDING.bgcolor;
        break;
    }
    return bg;
  };

  const statusTextColor = (status) => {
    let color = "";
    switch (status) {
      case "Confirmed":
        color = statusClasses.COMPLETED.textColor;
        break;

      case "Rejected":
        color = statusClasses.REJECTED.textColor;
        break;

      case "Pending":
        color = statusClasses.PENDING.textColor;
        break;
    }
    return color;
  };

  useEffect(() => {
    //setWatchListShown(true);
    setPageName("Orders");
  });

  useEffect(() => {
    setRenderOrder(orders);
  }, []);

  useEffect(() => {
    ordersFilter();
  }, [orderStatusCount, currentPage, orders]);

  const getOrder = async () => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    await fetch("https://app.stoxviews.com/api/getorder", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("order data : ", value);
        if (value.status) {
          setOrders(value.data.reverse());
          let pending = 0;
          let rejected = 0;
          let confirmed = 0;
          value.data.map((item, index) => {
            // let fields = item.fields;
            if (item.status === "Confirmed") {
              confirmed = confirmed + 1;
            }
            if (item.status === "Rejected") {
              rejected = rejected + 1;
            }
            if (item.status === "Pending") {
              pending = pending + 1;
            }
          });

          setTotalConfirmed(confirmed);
          setTotalPending(pending);
          setTotalRejected(rejected);
        } else {
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getliveorders = async (e) => {
    e.preventDefault();
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = { hmonth: orderMasterMonth };
    // console.log("order master live : ", body);

    await fetch("https://app.stoxviews.com/api/liveorders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("get live order data : ", value);
        setLoading(false);
        // if (value.status) {
        //   setOrders(value.data.reverse());
        //   let pending = 0;
        //   let rejected = 0;
        //   let confirmed = 0;
        //   value.data.map((item, index) => {
        //     // let fields = item.fields;
        //     if (item.status === "Confirmed") {
        //       confirmed = confirmed + 1;
        //     }
        //     if (item.status === "Rejected") {
        //       rejected = rejected + 1;
        //     }
        //     if (item.status === "Pending") {
        //       pending = pending + 1;
        //     }
        //   });

        //   setTotalConfirmed(confirmed);
        //   setTotalPending(pending);
        //   setTotalRejected(rejected);
        // } else {
        // }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getliveorderschild = async (e) => {
    e.preventDefault();
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = { hmonth: orderChildMonth };
    // console.log("order child live : ", body);
    await fetch("https://app.stoxviews.com/api/liveorderschild", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("get live order child data : ", value);
        setLoading(false);
        // if (value.status) {
        //   setOrders(value.data.reverse());
        //   let pending = 0;
        //   let rejected = 0;
        //   let confirmed = 0;
        //   value.data.map((item, index) => {
        //     // let fields = item.fields;
        //     if (item.status === "Confirmed") {
        //       confirmed = confirmed + 1;
        //     }
        //     if (item.status === "Rejected") {
        //       rejected = rejected + 1;
        //     }
        //     if (item.status === "Pending") {
        //       pending = pending + 1;
        //     }
        //   });

        //   setTotalConfirmed(confirmed);
        //   setTotalPending(pending);
        //   setTotalRejected(rejected);
        // } else {
        // }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getStatus = (status) => {
    let value = 0;
    if (status === "Confirmed") value = totalConfirmed;
    if (status === "Rejected") value = totalRejected;
    if (status === "Pending") value = totalPending;
    return value;
  };

  const ordersFilter = () => {
    const selected = orderStatusCount.filter((item, index) => item.isSelected);
    if (selected.length === 0) {
      let newOrderList = orders.filter((item) =>
        item.token_id__trading_symbol
          .toLowerCase()
          .includes(search.toLowerCase())
      );
      setRenderOrder(newOrderList);
    } else {
      const newOrders = orders.filter(
        (item, index) => item?.status === selected[0].status
      );
      let newOrderList = newOrders.filter((item) =>
        item.token_id__trading_symbol
          .toLowerCase()
          .includes(search.toLowerCase())
      );
      setRenderOrder(newOrderList);
    }

    //return orders;
  };

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    isDemoPage && getOrder();
    //isMasterOrChildOrder ? getliveorders() : getliveorderschild();
  }, [isMasterOrChildOrder]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [re, setRe] = useState("");
  // const []
  const togglePopup = (re) => {
    setIsPopupOpen(!isPopupOpen);
    setRe(re);
  };

  const callPendingEdit = (item) => {
    marginCalculation(
      qty,
      productType,
      item.act,
      item.token_id__exchange_token
    );
    setOrderType(item.otype);
    setProductType(item.ptype);

    if (item.ptype === "Intraday") {
      setIsTriggerDisabled(true);
    } else if (item.ptype === "Longterm") {
      setIsTriggerDisabled(true);
    }

    // if(item.ptype==="SL-M"){
    //   setIsDisabled(true);
    //   setIsTriggerDisabled(false);
    //   setIsTriggerRequired(true);
    // }else if(item.ptype==="SL"){
    //   setIsTriggerDisabled(false);
    //   setIsDisabled(false);
    //   setIsTriggerRequired(true);
    // }else if(item.ptype==="Intraday"){
    //   setIsTriggerDisabled(true);
    // }else if(item.ptype==="Longterm"){
    //   setIsTriggerDisabled(true);
    // }

    setOrderId(item.id);
    setBopen(true);
    setIsPendingOrder(true);
    setBuyAndSellTradingSymbol(item.token_id__trading_symbol);
    setBuyAndSellExchangeToken(item.token_id__exchange_token);
    setChartToken(item.token_id__exchange_token);
    setBuyAndSellExchange(item.token_id__exchange);
    setBuyAndSellModalLivePrice(item.price);
    setQty(item.qty);
    setLotSize(item.token_id__lot_size);
    setBuyAndSellAction(item.act);
  };
  return (
    <Layout>
      <>
        {isDemoPage ? (
          <div className="p-4">
            {loading && <Loader />}
            <div className="flex items-center justify-between mb-4">
              <div className="flex flex-col w-full mb-4">
                {/* <div className="flex flex-col w-6/12 mt-5 mb-5">
                <h2 className="mb-4">Date :</h2>
                <form className="flex ">
                  <select
                    id="options"
                    // value={selectedOption}
                    // onChange={handleChange}
                    style={{
                      padding: "10px 5px",
                      borderRadius: 4,
                      borderWidth: 1,
                      borderColor: "#817e7e",
                      color: "#817e7e",
                    }}
                  >
                    <option value="">Select Your Date</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                  <button
                    type="submit"
                    class="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Search
                  </button>
                </form>
              </div> */}

                <div className=" w-full flex items-center justify-between">
                  <div className="flex space-x-1">
                    <h1 className="text-xl font-bold mr-[36px] ">Orders</h1>
                    <button
                      onClick={() => {
                        setOrderStatusCount(
                          orderStatusCount.map((it, idx) => {
                            it.isSelected = false;

                            return it;
                          })
                        );
                        setIsAllSelected(true);
                      }}
                      className="px-3 py-1 border rounded bg-green-100 flex flex-row items-center"
                      style={{
                        fontSize: 14,
                        color: isAllSelected ? "#155724" : "#817E7E",
                        backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                      }}
                    >
                      ALL | {orders.length}
                    </button>

                    {orderStatusCount.map((item, index) => {
                      return (
                        <button
                          key={index}
                          className="px-3 py-1 border rounded"
                          onClick={() => {
                            setIsAllSelected(false);
                            setOrderStatusCount(
                              orderStatusCount.map((it, idx) => {
                                if (index === idx) {
                                  it.isSelected = true;
                                } else {
                                  it.isSelected = false;
                                }
                                return it;
                              })
                            );
                          }}
                          style={{
                            fontSize: 12,
                            color: item.isSelected ? "#155724" : "#817E7E",
                            backgroundColor: item.isSelected
                              ? "#D4EDDA"
                              : "#FFFFFF",
                          }}
                        >
                          {item.status} | {getStatus(item.status)}
                        </button>
                      );
                    })}
                  </div>
                  {/* <div className="flex justify-end items-center w-[32%]">
                  <h2>Search :</h2>
                  <div className="ml-6 flex justify-center ">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      id="voice-search"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search"
                      required
                    />
                  </div>
                </div> */}
                  <div className="mr-[16PX]">
                    <button>
                      <img
                        src={DownloadIcon}
                        alt="download"
                        style={{ width: 14, height: 16 }}
                        onClick={() => {
                          exportToSpreadSheet(currentItems, "Order_data");
                        }}
                      />
                    </button>
                    <button
                      className="ml-2"
                      onClick={() => {
                        getOrder();
                      }}
                    >
                      <SlReload />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <table className="min-w-full bg-white orderTable">
              <thead className="orderTable">
                <tr>
                  <th className="px-4 py-2 border">Time</th>
                  <th className="px-4 py-2 border">Type</th>
                  <th className="px-4 py-2 border">Instrument</th>
                  <th className="px-4 py-2 border">Product</th>
                  <th className="px-4 py-2 border">Qty.</th>
                  <th className="px-4 py-2 border">Avg Price</th>
                  <th className="px-4 py-2 border">Status</th>
                </tr>
              </thead>
              {/* ordersFilter() */}
              {currentItems.map((item, index) => {
                // let fields = item.fields;
                // console.log("order items: ", item);
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <Moment format="DD/MM/YYYY, h:mm:ss A">
                        {item.otime}
                      </Moment>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <span
                        style={{
                          color: item.act === "sell" ? "#7A282F" : "#155724",
                          backgroundColor:
                            item.act === "sell" ? "#F8D7DA" : "#D4EDDA",
                          fontSize: 10,
                        }}
                        className={`px-2 py-1 rounded orderTabletd`}
                      >
                        {item.act}
                      </span>
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                    >
                      {item.token_id__trading_symbol}
                      <span
                        style={{
                          fontSize: 10,
                          color: "#817E7E",
                          marginLeft: 3,
                        }}
                      >
                        {/* {order.instrumentType} */}
                      </span>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">{`${item.ptype} ${item.otype}`}</td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.price}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.status === "Rejected" ? (
                        // <div>
                        //   <button
                        //     className="tooltip"
                        //     type="button"
                        //     // class="btn btn-secondary"
                        //     // data-toggle="tooltip"
                        //     // data-placement="bottom"
                        //     title={item.reason}
                        //     style={{
                        //       color: "#721C24",
                        //       backgroundColor: "#F8D7DA",
                        //       fontSize: 10,
                        //       width: 80,
                        //       height: 20,
                        //       borderRadius: 3,
                        //     }}
                        //   >
                        //     Rejected
                        //   </button>
                        //   <div className="tooltiptext">{item.reason}</div>
                        // </div>
                        <>
                          <button
                            //className="tooltip"
                            onClick={() => togglePopup(item.reason)}
                            type="button"
                            // class="btn btn-secondary"
                            // data-toggle="tooltip"
                            // data-placement="bottom"
                            title={item.reason}
                            style={{
                              color: "#721C24",
                              backgroundColor: "#F8D7DA",
                              fontSize: 10,
                              width: 80,
                              height: 20,
                              borderRadius: 3,
                            }}
                          >
                            Rejected
                          </button>
                        </>
                      ) : (
                        <div className="flex flex-row justify-between">
                          <button
                            // onClick={() => {
                            //   console.log("status : ", item.status);
                            // }}
                            style={{
                              backgroundColor: statusBgColor(item.status),
                              color: statusTextColor(item.status),
                              fontSize: 10,
                              width: 80,
                              height: 20,
                              borderRadius: 3,
                            }}
                          >
                            {item.status}
                          </button>
                          {item.status === "Pending" && (
                            <button
                              onClick={() => {
                                callPendingEdit(item);
                              }}
                            >
                              <FaRegEdit />
                            </button>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </table>
            <div className="pagination">
              <button onClick={handlePrev} disabled={currentPage === 1}>
                Previous
              </button>

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handleClick(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}

              <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        ) : isMasterOrChildOrder ? (
          <div className="p-4">
            {loading && <Loader />}
            <div className="flex items-center justify-between mb-4">
              <div className="flex flex-col w-full mb-4">
                <h1 className="text-xl font-bold mr-[36px]">Orders</h1>

                <div className="flex flex-col w-6/12 mt-5 mb-5">
                  <h2 className="mb-4">Date :</h2>
                  <form className="flex " onSubmit={getliveorders}>
                    <select
                      id="options"
                      value={orderMasterMonth}
                      onChange={(e) => setOrderMasterMonth(e.target.value)}
                      placeholder="Select Your Date"
                      style={{
                        padding: "10px 5px",
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: "#817e7e",
                        color: "#817e7e",
                      }}
                    >
                      <option value="">Select Your Date</option>
                      <option value="0">January</option>
                      <option value="1">February</option>
                      <option value="2">March</option>
                      <option value="3">April</option>
                      <option value="4">May</option>
                      <option value="5">June</option>
                      <option value="6">July</option>
                      <option value="7">August</option>
                      <option value="8">September</option>
                      <option value="9">October</option>
                      <option value="10">November</option>
                      <option value="11">December</option>
                    </select>
                    <button
                      type="submit"
                      class="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Search
                    </button>
                  </form>
                </div>

                <div className=" w-full flex items-center justify-between">
                  <div className="flex justify-between w-[45%]">
                    <button
                      onClick={() => {
                        setOrderStatusCount(
                          orderStatusCount.map((it, idx) => {
                            it.isSelected = false;

                            return it;
                          })
                        );
                        setIsAllSelected(true);
                      }}
                      className="px-3 py-1 border rounded bg-green-100 flex flex-row items-center"
                      style={{
                        fontSize: 14,
                        color: isAllSelected ? "#155724" : "#817E7E",
                        backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                      }}
                    >
                      ALL | 25
                    </button>

                    {orderStatusCount.map((item, index) => {
                      return (
                        <button
                          key={index}
                          className="px-3 py-1 border rounded"
                          onClick={() => {
                            setIsAllSelected(false);
                            setOrderStatusCount(
                              orderStatusCount.map((it, idx) => {
                                if (index === idx) {
                                  it.isSelected = true;
                                } else {
                                  it.isSelected = false;
                                }
                                return it;
                              })
                            );
                          }}
                          style={{
                            fontSize: 12,
                            color: item.isSelected ? "#155724" : "#817E7E",
                            backgroundColor: item.isSelected
                              ? "#D4EDDA"
                              : "#FFFFFF",
                          }}
                        >
                          {item.status} | {getStatus(item.status)}
                        </button>
                      );
                    })}
                  </div>
                  <div className="flex justify-end items-center w-[32%]">
                    <h2>Search :</h2>
                    <div className="ml-6 flex justify-center ">
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        id="voice-search"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table className="min-w-full bg-white orderTable">
              <thead className="orderTable">
                <tr>
                  <th className="px-4 py-2 border">Sr No.</th>
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Type</th>
                  <th className="px-4 py-2 border">Broker</th>
                  <th className="px-4 py-2 border">Price</th>
                  <th className="px-4 py-2 border">Qty</th>
                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Log</th>
                  <th className="px-4 py-2 border">Execution Type</th>
                </tr>
              </thead>

              {currentItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <Moment format="DD/MM/YYYY, h:mm:ss A">
                        {item.otime}
                      </Moment>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <span
                        style={{
                          color: item.act === "sell" ? "#7A282F" : "#155724",
                          backgroundColor:
                            item.act === "sell" ? "#F8D7DA" : "#D4EDDA",
                          fontSize: 10,
                        }}
                        className={`px-2 py-1 rounded orderTabletd`}
                      >
                        {item.act}
                      </span>
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                    >
                      {item.token_id__trading_symbol}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">{`${item.ptype} ${item.otype}`}</td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.price}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.status === "Rejected" ? (
                        <>
                          <button
                            onClick={togglePopup}
                            type="button"
                            title={item.reason}
                            style={{
                              color: "#721C24",
                              backgroundColor: "#F8D7DA",
                              fontSize: 10,
                              width: 80,
                              height: 20,
                              borderRadius: 3,
                            }}
                          >
                            Rejected
                          </button>
                          {/* {isPopupOpen && (
                            <Popup onClose={togglePopup} reason={item.reason} />
                          )} */}
                        </>
                      ) : (
                        <button
                          style={{
                            backgroundColor: statusBgColor(item.status),
                            color: statusTextColor(item.status),
                            fontSize: 10,
                            width: 80,
                            height: 20,
                            borderRadius: 3,
                          }}
                        >
                          {item.status}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </table>
            <div className="pagination">
              <button onClick={handlePrev} disabled={currentPage === 1}>
                Previous
              </button>

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handleClick(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}

              <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className="p-4">
            {loading && <Loader />}
            <div className="flex items-center justify-between mb-4">
              <div className="flex flex-col w-full mb-4">
                <h1 className="text-xl font-bold mr-[36px]">Orders</h1>

                <div className="flex flex-col w-6/12 mt-5 mb-5">
                  <h2 className="mb-4">Date :</h2>
                  <form className="flex " onSubmit={getliveorderschild}>
                    <select
                      id="options"
                      value={orderChildMonth}
                      onChange={(e) => setOrderChildMonth(e.target.value)}
                      style={{
                        padding: "10px 5px",
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: "#817e7e",
                        color: "#817e7e",
                      }}
                    >
                      <option value="">Select Your Date</option>
                      <option value="0">January</option>
                      <option value="1">February</option>
                      <option value="2">March</option>
                      <option value="3">April</option>
                      <option value="4">May</option>
                      <option value="5">June</option>
                      <option value="6">July</option>
                      <option value="7">August</option>
                      <option value="8">September</option>
                      <option value="9">October</option>
                      <option value="10">November</option>
                      <option value="11">December</option>
                    </select>
                    <button
                      type="submit"
                      class="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Search
                    </button>
                  </form>
                </div>

                <div className=" w-full flex items-center justify-between">
                  <div className="flex justify-between w-[45%]">
                    <button
                      onClick={() => {
                        setOrderStatusCount(
                          orderStatusCount.map((it, idx) => {
                            it.isSelected = false;

                            return it;
                          })
                        );
                        setIsAllSelected(true);
                      }}
                      className="px-3 py-1 border rounded bg-green-100 flex flex-row items-center"
                      style={{
                        fontSize: 14,
                        color: isAllSelected ? "#155724" : "#817E7E",
                        backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                      }}
                    >
                      ALL | 25
                    </button>

                    {orderStatusCount.map((item, index) => {
                      return (
                        <button
                          key={index}
                          className="px-3 py-1 border rounded"
                          onClick={() => {
                            setIsAllSelected(false);
                            setOrderStatusCount(
                              orderStatusCount.map((it, idx) => {
                                if (index === idx) {
                                  it.isSelected = true;
                                } else {
                                  it.isSelected = false;
                                }
                                return it;
                              })
                            );
                          }}
                          style={{
                            fontSize: 12,
                            color: item.isSelected ? "#155724" : "#817E7E",
                            backgroundColor: item.isSelected
                              ? "#D4EDDA"
                              : "#FFFFFF",
                          }}
                        >
                          {item.status} | {getStatus(item.status)}
                        </button>
                      );
                    })}
                  </div>
                  <div className="flex justify-end items-center w-[32%]">
                    <h2>Search :</h2>
                    <div className="ml-6 flex justify-center ">
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        id="voice-search"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table className="min-w-full bg-white orderTable">
              <thead className="orderTable">
                <tr>
                  <th className="px-4 py-2 border">Sr No.</th>
                  <th className="px-4 py-2 border">Order No</th>
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Type</th>
                  <th className="px-4 py-2 border">Client Name</th>
                  <th className="px-4 py-2 border">Symbol</th>
                  <th className="px-4 py-2 border">Price</th>
                  <th className="px-4 py-2 border">Qty</th>
                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Rejection Reason</th>
                </tr>
              </thead>

              {currentItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <Moment format="DD/MM/YYYY, h:mm:ss A">
                        {item.otime}
                      </Moment>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <span
                        style={{
                          color: item.act === "sell" ? "#7A282F" : "#155724",
                          backgroundColor:
                            item.act === "sell" ? "#F8D7DA" : "#D4EDDA",
                          fontSize: 10,
                        }}
                        className={`px-2 py-1 rounded orderTabletd`}
                      >
                        {item.act}
                      </span>
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                    >
                      {item.token_id__trading_symbol}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">{`${item.ptype} ${item.otype}`}</td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.price}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {item.status === "Rejected" ? (
                        <>
                          <button
                            onClick={togglePopup}
                            type="button"
                            title={item.reason}
                            style={{
                              color: "#721C24",
                              backgroundColor: "#F8D7DA",
                              fontSize: 10,
                              width: 80,
                              height: 20,
                              borderRadius: 3,
                            }}
                          >
                            Rejected
                          </button>
                          {/* {isPopupOpen && (
                            <Popup onClose={togglePopup} reason={item.reason} />
                          )} */}
                        </>
                      ) : (
                        <button
                          style={{
                            backgroundColor: statusBgColor(item.status),
                            color: statusTextColor(item.status),
                            fontSize: 10,
                            width: 80,
                            height: 20,
                            borderRadius: 3,
                          }}
                        >
                          {item.status}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </table>
            <div className="pagination">
              <button onClick={handlePrev} disabled={currentPage === 1}>
                Previous
              </button>

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handleClick(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}

              <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {isPopupOpen && <Popup onClose={() => togglePopup("")} reason={re} />}
      </>
    </Layout>
  );
}

export default Orders;
