import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import { FiSearch } from "react-icons/fi";
import { MdDownload } from "react-icons/md";
import rupeeIcon from "../../assets/icons/rupee.png";
import { SlReload } from "react-icons/sl";
import { exportToSpreadSheet } from "../../components/xlsx/ExportToSpreadSheet";

function Holdings() {
  const {
    setWatchListShown,
    setPageName,
    demoHolding,
    setDemoHolding,
    totalCur,
    setTotalCur,
    totalInvestment,
    setTotalInvestment,
    totalPnl,
    setTotalPnl,
    setBopen,
    setBuyAndSellTradingSymbol,
    setBuyAndSellExchangeToken,
    setChartToken,
    setBuyAndSellExchange,
    setBuyAndSellModalLivePrice,
    setQty,
    setLotSize,
    setBuyAndSellAction,
    setIsToggled,
    getHoldingPageData,
    setIsPendingOrder,
    setOrderType,
    setProductType,
    qty,
    marginCalculation,
    productType,
    totalTodayPnl,
    setTotalTodayPnl,
  } = useContext(MyContext);
  const [isShown, setIsShown] = useState(false);

  // console.log("Demo holding : ",demoHolding);
  useEffect(() => {
    // setWatchListShown(true);

    // console.log("set total cur : ",totalCur);
    // console.log("set total investment: ",totalInvestment);
    // console.log("set total pnl : ",totalPnl);

    // getHoldingPageData();
    setPageName("Holdings");
  }, []);

  const onOpenModal = (
    action,
    trading_symbol,
    Exchange,
    token,
    lastPrice,
    lotSize
  ) => {
    // console.log("optionchain : ", item);
    setOrderType("Limit");
    setProductType("Intraday");
    setIsPendingOrder(false);
    setBopen(true);
    setBuyAndSellTradingSymbol(trading_symbol);
    setBuyAndSellExchangeToken(token);
    setChartToken(token);
    setBuyAndSellExchange(Exchange);
    setBuyAndSellModalLivePrice(lastPrice);
    setQty(1);
    setLotSize(lotSize);
    if (action === "BUY") {
      setBuyAndSellAction("buy");
      marginCalculation(qty, productType, "buy", token);
      setIsToggled(false);
    } else {
      setBuyAndSellAction("sell");
      marginCalculation(qty, productType, "sell", token);
      setIsToggled(true);
    }
  };

  let todayPnl = ((totalInvestment - totalTodayPnl) / totalInvestment) * 100;

  let dayPnl = ((totalTodayPnl - totalInvestment) / totalInvestment) * 100;

  return (
    <Layout>
      <div
        className="w-full h-auto border-[1px] mb-[10px]"
        style={{ borderColor: "#E6E6E6", borderRadius: 4 }}
      >
        <div className="flex  items-center justify-between ml-[16px] mt-[24px] mr-[16px] mb-[23px]">
          <div className="flex  items-center ">
            <h1 className="text-xl font-bold mr-[36px]">Holdings</h1>
            <div className="flex space-x-1">
              <button
                className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                style={{
                  fontSize: 14,
                  backgroundColor: "#D4EDDA",
                  color: "#155724",
                  //color: isAllSelected ? "#155724" : "#817E7E",
                  //backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                }}
              >
                Stocks | {demoHolding.length}
              </button>
            </div>
          </div>

          <div>
            <button>
              <FiSearch />
            </button>
            <button
              style={{ width: 14, height: 16, marginLeft: 16 }}
              onClick={() => exportToSpreadSheet(demoHolding, "Holding_data")}
            >
              <MdDownload />
            </button>
            <button
              className="ml-2"
              onClick={() => {
                setDemoHolding([]);
                setTotalInvestment(0.0);
                setTotalCur(0.0);
                setTotalPnl(0.0);
                getHoldingPageData();
              }}
            >
              <SlReload />
            </button>
          </div>
        </div>

        <table className="min-w-full  bg-white orderTable">
          <thead className="orderTable">
            <tr>
              <th>Instrument</th>
              <th>Qty.</th>
              <th>Avg. cost</th>
              <th>LTP</th>
              <th>Cur. val</th>
              <th>P&L</th>
              <th>Day Profit</th>
              <th>Net chg.</th>
            </tr>
          </thead>
          <tbody>
            {demoHolding.map((holding, hindex) => {
              console.log("holding data : ", holding.instrument__open_price);

              let cur_value = holding.buy_qty * parseFloat(holding.last_price);
              let pnl = cur_value - holding.buy_avg * holding.buy_qty;
              let netChg =
                (holding.change / parseFloat(holding.last_price)) * 100;

              // let todayPnl =
              //   ((totalInvestment - totalTodayPnl) / totalInvestment) * 100;

              return (
                <tr key={hindex}>
                  <td className="px-4 py-2 border orderTabletd">
                    <div
                      className=""
                      onMouseOver={() => {
                        const newDemoHolding = demoHolding.map((it, idx) => {
                          if (hindex === idx) {
                            it.isHover = true;
                          } else {
                            it.isHover = false;
                          }

                          return it;
                        });
                        setDemoHolding(newDemoHolding);
                      }}
                      onMouseOut={() => {
                        const newDemoHolding = demoHolding.map((it, idx) => {
                          it.isHover = false;

                          return it;
                        });
                        setDemoHolding(newDemoHolding);
                      }}
                    >
                      <div className="flex items-center">
                        <h2 style={{ fontSize: 14, color: "#363636" }}>
                          {holding.instrument__trading_symbol}
                        </h2>
                        <span
                          className="ml-[4px]"
                          style={{ fontSize: 10, color: "#817E7E" }}
                        >
                          {holding.instrument__exchange}
                        </span>
                      </div>
                      {holding.isHover && (
                        <div className="addorexit">
                          <button
                            className="addBtn cursor-pointer"
                            onClick={() => {
                              onOpenModal(
                                "BUY",
                                holding.instrument__trading_symbol,
                                holding.instrument__exchange,
                                holding.instrument__exchange_token,
                                holding.instrument__last_price,
                                holding.instrument__lot_size
                              );
                            }}
                          >
                            <p>Add</p>
                          </button>
                          <button
                            className="exitBtn cursor-pointer"
                            onClick={() => {
                              onOpenModal(
                                "SELL",
                                holding.instrument__trading_symbol,
                                holding.instrument__exchange,
                                holding.instrument__exchange_token,
                                holding.instrument__last_price,
                                holding.instrument__lot_size
                              );
                            }}
                          >
                            <p>Exit</p>
                          </button>
                        </div>
                      )}
                    </div>
                  </td>

                  <td className="px-4 py-2 border orderTabletd">
                    {holding.buy_qty}
                  </td>

                  <td className="px-4 py-2 border orderTabletd">
                    {holding.buy_avg}
                  </td>

                  <td className="px-4 py-2 border orderTabletd">
                    {parseFloat(holding.last_price).toFixed(2)}
                  </td>

                  <td className="px-4 py-2 border orderTabletd">
                    {cur_value.toFixed(2)}
                  </td>

                  <td
                    className="px-4 py-2 border orderTabletd"
                    style={{ color: pnl < 0 ? "#DF2123" : "green" }}
                  >
                    {pnl.toFixed(2)}
                  </td>

                  <td
                    className="px-4 py-2 border orderTabletd"
                    style={{
                      color:
                        holding.instrument__open_price *
                          holding.buy_qty *
                          holding.instrument__lot_size -
                          holding.instrument__last_price *
                            holding.buy_qty *
                            holding.instrument__lot_size >
                        0
                          ? "#DF2123"
                          : "green",
                    }}
                  >
                    {(
                      holding.instrument__last_price *
                        holding.buy_qty *
                        holding.instrument__lot_size -
                      holding.instrument__open_price *
                        holding.buy_qty *
                        holding.instrument__lot_size
                    ).toFixed(2)}
                  </td>

                  {/* open_price*buy_qty*lot_size */}

                  <td
                    className="px-4 py-2 border orderTabletd"
                    style={{ color: netChg < 0 ? "#DF2123" : "green" }}
                  >
                    {netChg.toFixed(2)}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="mt-[27px] mb-[15px]  flex justify-end space-x-9 px-6 ">
          <div>
            <div className="text-xs text-gray-500">Total Investment</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span
                className="text-lg font-bold"
                style={{ color: totalInvestment < 0 ? "#DF2123" : "#000000" }}
              >
                {totalInvestment.toFixed(2)}
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Current Value</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span
                className="text-lg font-bold"
                style={{ color: totalCur <= 0 ? "#DF2123" : "#000000" }}
              >
                {totalCur.toFixed(2)}
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Day P&L</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span
                className="text-lg font-bold"
                style={{
                  color: totalTodayPnl <= 0 ? "#DF2123" : "green",
                }}
              >
                {totalTodayPnl.toFixed(2)}
                <span
                  style={
                    {
                      // color:
                      //   ((totalTodayPnl - totalInvestment) / totalInvestment) *
                      //     100 <
                      //   0
                      //     ? "#DF2123"
                      //     : "#00FF00",
                    }
                  }
                >{`(${((totalTodayPnl / totalInvestment) * 100).toFixed(
                  2
                )}%)`}</span>
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Today's P&L</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span
                className="text-lg font-bold"
                style={{
                  color: totalCur - totalInvestment <= 0 ? "#DF2123" : "green",
                }}
              >
                {(totalCur - totalInvestment).toFixed(2)}
                <span
                  style={
                    {
                      // color:
                      //   ((totalCur - totalInvestment) / totalInvestment) * 100 < 0
                      //     ? "#DF2123" : "#00FF00",
                    }
                  }
                >{`(${(
                  ((totalCur - totalInvestment) / totalInvestment) *
                  100
                ).toFixed(2)}%)`}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Holdings;
